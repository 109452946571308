@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;700&display=swap');



html {
  background-color: #dcdcdc;
}

body {
  margin: 5px auto 0;
  padding: 0px;
  padding-right: 0px !important;
  overflow: auto;
  width: 95%;
  max-width: 950px;
  min-height: 500px;
  border: 4px solid #535353;
  background-color: #ffffff;
  font-family: 'Teko', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: normal;
  padding-left: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.westco-red {
  background-color: #c40f33;
}